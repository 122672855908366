var exports = {};
// Somewhat based on:
// <https://github.com/defunctzombie/node-process/blob/master/browser.js>.
// But I don’t think one tiny line of code can be copyrighted. 😅
exports.cwd = cwd;

function cwd() {
  return "/";
}

export default exports;
const _cwd = exports.cwd;
export { _cwd as cwd };